<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9 px-2">
        <div class="card card-custom gutter-b">
          <div class="form-group row d-flex align-items-center flex-col px-6 pt-4" style="gap: 8px;">
            <label class="col-12 col-md-4 font-weight-bold">{{ $t('LOGISTIC_ANALYSIS.DEVICE_FILTERS') }}:</label>
            <div class="col-12 col-md-8 d-flex flex-wrap" style="gap: 8px;">
              <div v-for="(filter, index) in filters" :key="filter.name" class="custom-checkbox-container">
                <input
                  type="checkbox"
                  :id="filter.name"
                  :checked="filter.value"
                  @change="onFilterChange(index)"
                  class="custom-checkbox"
                />
                <label :for="filter.name" class="custom-label">{{ filter.name }}</label>
              </div>
            </div>
          </div>
          <div v-if="filters.every(filter => !filter.value)" class="row">
            <div class="col-12 text-center pt-4 pb-6 pl-10">
              <h3 class="card-title" style="font-size: 14px; font-weight: 400;">{{ $t('LOGISTIC_ANALYSIS.APPLY_FILTER') }}</h3>
            </div>
          </div>
          <div v-else-if="!areFilteredDevicesLoaded" class="row">
            <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </div>
          <div v-else-if="areFilteredDevicesLoaded && devices.length === 0 && filters.some((item) => item.value)" class="row">
            <div class="col-12 text-center pl-10">
              <h3 class="card-title" style="font-size: 14px; font-weight: 400;">{{ $t('LOGISTIC_ANALYSIS.NO_DATA') }}</h3>
            </div>
          </div>
          <div v-else-if="devices.length > 0 && filters.some(filter => filter.value)" class="card-body px-2" style="margin-top: -24px;">
            <DualListBoxDevices
              :source="filteredDevices"
              :destination="selectedDevices"
              label="name"
              @onChangeList="onChangeList"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            />
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="pt-4 px-0">
          <button
            v-if="!isGenerated"
            class="btn btn-primary btn-block font-weight-bold"
            @click="generate"
          >
            Generate
          </button>
          <button
            v-if="selectedDevices.length > 0"
            class="btn btn-light-danger btn-block font-weight-bold"
            @click="resetAll"
          >
            Reset All
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <b-spinner v-if="showSpinner" variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12">
        
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Locations Traffic</h3>
          </div>
          <div v-if="!isLoaded" class="my-5">
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
            </div>
          </div>
          <div v-else-if="isLoaded && generatedResults.length > 0 && generatedDeviceResults.length > 0" class="card-body p-0">
            <LocationsTraffic
              :traffic="generatedDeviceResults"
              :generatedResults="generatedResults"
              :selected="selectedDevices"
              :range="range"
              :deviceType="this.filters.find((item) => item.value).name"
            />
          </div>
          <div v-else-if="isLoaded && generatedResults.length === 0" class="my-5">
            <div class="text-center my-2">
              <strong class="ml-4">No data</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Transactions Traffic</h3>
          </div>
          <div v-if="!isLoaded" class="my-5">
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
            </div>
          </div>
          <div v-else-if="isLoaded && generatedResults.length > 0 && selectedDevices.length > 0" class="card-body p-0">
            <TransactionsTraffic
              :traffic="generatedResults"
              :selected="selectedDevices"
              :range="range"
              :deviceType="this.filters.find((item) => item.value).name"
            />
          </div>
          <div v-else-if="isLoaded && (generatedResults.length === 0 || selectedDevices.length === 0)" class="my-5">
            <div class="text-center my-2">
              <strong class="ml-4">No data</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DualListBoxDevices from '@/view/content/components/dual-list-box/DualListBoxDevices'
import LocationsTraffic from '@/view/pages/logistic-report/_components/LocationsTraffic'
import TransactionsTraffic from '@/view/pages/logistic-report/_components/TransactionsTraffic'
import ApiService from '@/core/services/api.service'
import format from 'date-fns/format'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import getMonth from 'date-fns/getMonth'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import endOfMonth from 'date-fns/endOfMonth'
import addDays from 'date-fns/addDays'

export default {
  name: 'DeviceFinanceReport',
  components: {
    DatePicker,
    DualListBoxDevices,
    LocationsTraffic,
    TransactionsTraffic
  },
  data() {
    return {
      filters: [
        { name: 'Saldo Reducers', value: false }, 
        { name: 'Monsters', value: false }, 
        { name: 'Prize Devices', value: false }, 
      ],
      devices: [],
      selectedDevices: [],
      filteredDevices: [],
      areFilteredDevicesLoaded: false,
      generatedResults: [],
      resultsDays: [],
      totalItems: null,
      range: {
        start: add(new Date(), { days: -1 }).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      showAlert: false,
      alertMsg: null,
      base: 0,
      isGenerated: false,
      showSpinner: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
      'currentUserPersonalInfo',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Device', route: '/logistic-report/device' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('select-device',
        `?companyId=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}`).then(
        ({ data }) => {
          this.totalItems = data.length
          this.devices = data
          this.devices.forEach((device, index) => {
         
            this.devices[index].name =
              device.name +
              ' | ' +
              device.location +
              ' | ' +
              device.tag
          })
        }
      )
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false&new=1&select=flags,timestamps`
      ).then(({ data }) => {
        this.totalItems = data.length
        this.devices = [...data['hydra:member']]
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name +
            ' | ' +
            device.locationName +
            ' | '
        })
      })
    }

    if (this.currentUserRole === 'ROLE_WATCHER') {
      ApiService.get(
        'users',
        `?email=${this.currentUserPersonalInfo.email}`
      ).then(({ data }) => {
        const userLocations = data['hydra:member'][0]['locations']

        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&location.id=${userLocations[0].id}&pagination=false`
        ).then(({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.devices = [...data['hydra:member']]
        })
      })
    }
  },
  methods: {
    onFilterChange(selectedIndex) {
      this.filters.forEach((filter, index) => {
        filter.value = index === selectedIndex ? !filter.value : false;
      });

      const selectedFilter = this.filters.find((item) => item.value);

      const saldoReducers = this.devices.filter(device => device.isSaldoReducer === 1)
      const prizeDevices = this.devices.filter(device => device.isPrizeDevice === 1)
      const monsterDevices = this.devices.filter(device => device.isMonsterDevice === 1)
      
      if (selectedFilter.name) {
        this.filteredDevices = []
        this.selectedDevices = []
        this.filteredDevices = selectedFilter.name === 'Saldo Reducers'
          ? saldoReducers
          : selectedFilter.name === 'Prize Devices'
          ? prizeDevices: selectedFilter.name === 'Monsters'
          ? monsterDevices : [];
      }

      this.areFilteredDevicesLoaded = true;
    },
    generate() {
      if (
        this.selectedDevices.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select device/s & pick range'
        this.showAlert = true

        return
      }

      this.showSpinner = true
      this.isGenerated = false
      this.generatedResults = []
      this.generatedDeviceResults = []
      let num = 0

      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      this.selectedDevices.forEach((device) => {
        ApiService.get(
                'devices',`?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false&new=1&select=flags,timestamps`
                ).then(({ data }) => {
                  if(data)
                    data['hydra:member'].map((item) => item.id === device.id &&
                    this.generatedDeviceResults.push(item)
                  )
               })
      })

      this.selectedDevices.forEach((device) => {
        if (this.filters.find((item) => item.value).name === 'Saldo Reducers') {

          ApiService.get('messages', `?imei=${device.imei}&pagination=false&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc`)
            .then(({ data }) => {
              const messages = data['hydra:member'];

              ApiService.get('device_configurations', `?imei=${device.imei}`)
                .then(({ data }) => {
                  const deviceConfig = data['hydra:member'][0];
                  const cctalkConfig = deviceConfig.configuration.cctalk;

                  if (cctalkConfig && cctalkConfig.status) {
                    const outputValues = cctalkConfig.output_values;
                    
                    messages
                      .filter((message) => 
                        message.data.some((item) => item.type === 'pay_cctalk')
                      )
                      .map((message) => {
                        const payloadItem = message.data.find((item) => item.type === 'pay_cctalk');
                        
                        const amount = outputValues[payloadItem.payload];

                        this.generatedResults.push({
                          deviceName: device.name, 
                          imei: device.imei, 
                          amount: amount, 
                          paymentType: 'pay_cctalk',
                          createdAt: format(new Date(message.createdAt), 'dd.MM.yyyy @ HH:mm:ss'), 
                          tags: []
                        })
                        
                      });

                  } else {
                    console.log(`CCTalk configuration is not available.`);
                  }
                })
                .catch(error => {
                  console.log(`Error fetching device configuration: ${error}`);
                })
                .finally(() => {
                  this.isLoaded = true
                });
            })
            .catch(error => {
              console.log(`Error fetching messages: ${error}`);
            });
        } else if (this.filters.find((item) => item.value).name === 'Monsters' || this.filters.find((item) => item.value).name === 'Prize Devices') {
          ApiService.get(
                'transactions',
                `?device=${device.id}&company=${this.currentUserCompany}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
                ).then(({ data }) => {
                    data['hydra:member'].forEach(
                        ({
                          id,
                          imei,
                          deviceMeta,
                          amount,
                          paymentType,
                          createdAt,
                          locationMeta
                        }) => {
                          if(amount >= 0 && paymentType !== 'token_drop') {
                            this.generatedResults.push({
                              id: id,
                              deviceId: device.id,
                              isSaldoReducer: device.isSaldoReducer,
                              isPrizeDevice: device.isPrizeDevice,
                              isMonsterDevice: device.isMonsterDevice,
                              deviceSaldo: device.saldo,
                              tokenMinimum: device.tokenMinimum,
                              saldoMinimum: device.saldoMinimum,
                              imei: imei,
                              token: device.token,
                              deviceName: deviceMeta.name,
                              location: locationMeta.title ? locationMeta.title : '',
                              lessor: locationMeta.hasOwnProperty('lessor')
                                ? locationMeta.lessor
                                : '',
                              address: locationMeta.address ? locationMeta.address : '',
                              tags: locationMeta.tags ? locationMeta.tags : [],
                              amount: amount,
                              paymentType: paymentType,
                              createdAt: format(new Date(createdAt), 'dd.MM.yyyy @ HH:mm:ss'),
                            })
                          }
                        }
                    )
            }).finally(() => {
                  this.isLoaded = true
                });
        }
        num++
        if (num === this.selectedDevices.length) {
            setTimeout(() => {
                this.isGenerated = true
                this.showSpinner = false
           }, 1000)
        }
      });
    },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -8 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -31 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    resetAll() {
      this.generatedResults = []
      this.resultsDays = []
      this.totalItems = null
      this.range.start = null
      this.range.end = null
      this.base = 0
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false
    },
    onChangeList: function ({ source, destination }) {
      this.filteredDevices = source
      this.selectedDevices = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
  },
}
</script>

<style lang="scss">
.card-title {
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
/* Container for the custom checkbox and label */
.custom-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Hide the default checkbox appearance */
.custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;
}

/* Add a checkmark when checked */
.custom-checkbox:checked::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 5px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Background color on check */
.custom-checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
}

/* Add a hover effect */
.custom-checkbox:hover {
  border-color: #0056b3;
}

/* Label styling */
.custom-label {
  font-size: 18px;
  cursor: pointer;
  color: #333;
  margin-top: 8px;
}

</style>
